@import '../../../styles/colors';

.dropdownContent {
  position: fixed !important;
  bottom: 20px;
  width: 540px !important;
  background: $lightBlue;
  overflow-y: auto;
  min-height: 90vh;
  border-color: $lightGray2;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-sizing: border-box;
  font-weight: 500;
  color: #646D77;
  z-index: 1;
  text-align: start;
}

textarea {
  resize: none;
  border-radius: 4px;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid $lightGray2;
  background: $lightBlue;
  font-size: 13px;
  box-sizing: border-box;
  color: $black;
  margin-bottom: 16px;
  &::placeholder {
    color: $gray;
  }
  &:focus {
    outline: 1px solid $blue1;
  }
}

.textError {
  color: $red;
  font-size: 12px;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  text-align: left;
}

.selectInput {
  input {
    text-transform: capitalize;
  }
}
