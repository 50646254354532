@import '../../../styles/colors';

.container {
  width: 100%;

  .buttonContainer {
    margin-bottom: 0;
    .button {
      background: $blue2;
    }
  }
}
