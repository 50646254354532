@import '../../../styles/colors';

.container {
  margin-bottom: 15px;
  span {
    font-weight: 600;
    font-size: 12px;
    color: $gray3;
  }
  a {
    color: $gray;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-decoration: none;
    margin-right: 16px;
    position: relative;

    &:after {
      content: '/';
      color: $gray;
      position: absolute;
      top: 0;
      right: -10px;
      cursor: default;
    }
  }
}
