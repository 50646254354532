@import '../../../styles/colors';

.header {
  background: linear-gradient(88.38deg, #126FBC 34.4%, #178ACC 54.47%);

  .container {

    .logoContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        padding: 15px 0;
        display: block;
      }

      .buttonContainer {
        margin-bottom: 0;

        .button {
          color: $blue3;
          padding: 8px 15px;
          border-radius: 4px;
          text-transform: capitalize;
          border: none;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .navigationContainer {
        display: flex;
        align-items: center;

        .breadCrumbsContainer {
          padding: 13px 33px 12px 33px;
          background: $white;
          border-radius: 8px 8px 0 0;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: $gray3;
          margin-right: 12px;
          text-transform: capitalize;

          a {
            color: $gray;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            text-decoration: none;
            margin-right: 16px;
            position: relative;

            &:after {
              content: '/';
              color: $gray;
              position: absolute;
              top: 0;
              right: -10px;
              cursor: default;
            }
          }
        }

        .newActionContainer {
          margin: 0 12px 0 0;

          .newActionButton {
            height: 40px;
            padding: 0 23px 0 15px;
            border-radius: 8px 8px 0 0;
            box-shadow: none;
            background: linear-gradient(180deg, #FFFFFF 77.5%, #F5F2F2 100%);
            border: 1px solid #E2E6ED;
            color: #363A42;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;

            &:hover {
              opacity: 1;
            }

            svg {
              margin-right: 12px;
            }
          }
        }

        .switchPagesContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 6px;
          a {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $blue3;
            padding: 9px;
            border-radius: 4px;
            margin-right: 8px;
          }
        }
      }
      .menuContainer {
        display: flex;
        align-items: flex-end;

        .menuContainerItem {
          position: relative;
          margin: 0 0 8px 8px;
          display: flex;
          align-items: flex-end;
          cursor: default;
          padding: 0;
          background-color: transparent;

          &:nth-child(6) {
            cursor: pointer;

            &:hover {
              .imageHover {
                visibility: visible;
              }
            }
          }

          .imageHover {
            position: absolute;
            visibility: hidden;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

.header.whiteHeader {
  background: $white;

  .container {
    .actions {
      .navigationContainer {
        .breadCrumbsContainer {
          background: $blue4;
          color: $white;
          font-weight: 400;

          a {
            color: $white;

            &:after {
              color: $white;
              opacity: 0.4;
            }
          }
        }
      }
    }

    .buttonContainer {
      .button {
        background: $blue3;
        color: $white;
      }
    }
  }
}
