@import '../../../styles/colors';


.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 37px 0 240px 0;
    min-width: 800px;

    .headerContainer {
      display: flex;
      align-items: center;
      margin-bottom: 23px;

      .headerTitle {
        width: 270px;
        margin-right: 13px;
        justify-content: flex-start;
        background: none;
        border: none;
        padding: 0;

        .title {
          color: #363A42;
          font-weight: 600;
          font-size: 14px;
        }

        .tipTextContainer {
          transform: translate(5%, -14%);
        }

        .triangle {
          left: 0;
          top: 5%;
          transform: rotate(-90deg) translateY(-12px);
        }
      }

      .titleCapabilities {
        font-weight: 600;
        font-size: 14px;
        color: #363A42;
        padding: 0;
      }
    }

    .itemContainer {
      display: flex;
      padding: 0 1px;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .offeringContainer {
        width: 270px;
        margin-right: 13px;
        display: flex;
      }

      .capabilitiesContainer {
        flex: 1;

        .capabilities {
          display: flex;

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          &:last-of-type {
            .buttonContainer {
              visibility: visible;
            }
          }

          &:nth-child(10) {
            .buttonContainer {
              visibility: hidden;
            }
          }

          .buttonContainer {
            margin: 0 0 0 8px;
            width: 43px;
            visibility: hidden;

            button {
              background: #DDE9FF;
              border: 1px solid #3179C1;
              border-radius: 4px;
              padding: 0;
              height: 100%;
              box-shadow: none;
            }
          }
        }
      }

      .inputContainer {
        margin: 0 8px 0 0;
        flex: 1;

        input {
          font-weight: 500;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .selectContainer {
        width: 90px;
        margin-bottom: 0;

        input {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}
