@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 32px 0 67px 0;
    min-width: 1000px;

    .M6ItemContainer {
      padding: 0 1px 32px 0;
      border-bottom: 1px solid $lightGray2;

      &:not(:last-child) {
        margin-bottom: 32px;
      }

      .title {
        width: calc(50% - 15px);
        margin-bottom: 16px;
      }

      .serviceableObtainableMarket {
        display: flex;
        margin-bottom: 16px;

        .percentContainer {
          flex: 1;
          margin-right: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .percentTitle {
            font-weight: 600;
            font-size: 15px;
            color: #363A42;
            line-height: 17px;
            margin-right: 9px;
          }

          .percentInputContainer {
            position: relative;
            margin-left: 8px;
            .percentInput {
              margin-bottom: 0;
              width: 76px;

              input {
                font-weight: 500;
                font-size: 14px;
                padding-right: 25px;
              }
            }

            span {
              position: absolute;
              top: 50%;
              right: 12px;
              transform: translateY(-50%);
              font-weight: 500;
              font-size: 14px;
            }
          }
        }

        .valuesContainer {
          flex: 1;
          margin-left: 15px;
          display: flex;

          .valuesInputContainer {
            position: relative;
            flex: 1;
            max-width: 200px;

            &:first-child {
              margin-right: 15px;
            }

            .valuesInput {
              margin-bottom: 0;

              input {
                padding-left: 30px;
                padding-right: 30px;
                font-weight: 500;
                font-size: 14px;
                text-align: center;
              }
            }

            span {
              position: absolute;
              top: 28%;
              left: 15px;
              font-weight: 500;
              font-size: 14px;
            }
          }

          .valuesInput {
            margin-bottom: 0;
            flex: 1;

            input {
              font-weight: 500;
              font-size: 14px;
            }
          }
        }
      }

      .M6ItemContent {
        display: flex;

        .avatarContainer {
          width: 190px;
        }
      }

      .offeringContainer {
        flex: 1;

        .offeringItemContainer {
          display: flex;

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          .offering {
            width: calc(50% - 110px);
            display: flex;

            .offeringInput {
              margin-bottom: 0;
              margin-right: 0;

              input {
                font-weight: 500;
                font-size: 14px;
                padding-top: 10px;
                padding-bottom: 10px;
              }
            }

            .offeringSelectInput {
              margin-bottom: 0;
              margin-left: 8px;
              max-width: 90px;

              input {
                font-weight: 500;
                font-size: 14px;
                padding-top: 10px;
                padding-bottom: 10px;
              }
            }
          }

          .som {
            flex: 1;
            margin-left: 30px;
            display: flex;
            align-items: center;

            .somTitle {
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: $black;
              min-width: 174px;
            }

            .somInput {
              margin-bottom: 0;

              input {
                padding-left: 30px;
                font-weight: 500;
                font-size: 14px;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-transform: capitalize;
                padding-top: 10px;
                padding-bottom: 10px;
              }
            }

            .somInputValue {
              min-width: 75px;

              input {
                padding-left: 19px;
                padding-right: 10px;
                text-align: center;
              }
            }

            .somInputContainer {
              position: relative;
              margin-left: 8px;
              flex: 1;

              span {
                position: absolute;
                top: 28%;
                left: 10px;
                font-weight: 500;
                font-size: 14px;
              }
            }

            .somInputPercent {
              max-width: 86px;
            }
          }
        }
      }
    }
  }
}
