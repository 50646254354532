@import 'fonts';
@import 'variables';
@import 'colors';

body {
  margin: 0;
  padding: 0;
  background: $lightGray;
}

h1, h2, h4 {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: #fff;
  outline: none;
  &:hover {
    cursor: pointer;
  }
}

.container {
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
}

html,
body {
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: 100%;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}
