@import '../../../styles/colors';

.selected {
  font-weight: 500;
  background: $lightBlue1;
  color: $black !important;
}

.child {
  padding: 6px 16px 6px 30px !important;
}

.selectTitle {
  background: $white !important;
  font-weight: 700 !important;
  color: $lightBlue2 !important;
}

.dropdownItem {
  padding: 9px 16px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-transform: capitalize;
  color: $lightBlue2;
  font-weight: 500;
  margin: 0;
  text-align: left;

  &:disabled {
    cursor: default;
    background: $blackOpacity2;
    font-weight: 700;
    color: $blackOpacity;

    &:hover {
      color: $blackOpacity;
      background: $blackOpacity2;
      font-weight: 700;
    }
  }

  span:hover {
    scale: 1.2;
  }

  &:hover {
    font-weight: 600;
    background: $lightGray2;
    color: $black;
  }
}
