@import '../../../../styles/colors';

@import '../../../../styles/colors';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    max-width: 540px;
    width: 100%;
    margin: 32px 0;

    .inputContainer {
      margin-bottom: 18px;

      .dropdownContent {
        text-transform: capitalize;
      }
    }

    .selectInputContainer {
      margin-bottom: 18px;

      input {
        text-transform: capitalize;
      }
    }

    .title {
      color: $black;
      font-weight: 700;
      font-size: 19px;
      text-transform: uppercase;
      line-height: 19px;
      margin-bottom: 24px;
    }

    .buttonsContainer {
      display: flex;
      background: #F5F5F5;
      border-radius: 12px;
      padding: 24px;

      .buttonContainer {
        margin-bottom: 0;
        width: 100%;

        &:first-child {
          margin-right: 9px;
          .button {
            color: $blue6;
          };
        }

        &:last-child {
          margin-left: 9px;
          .button {
            background: $blue7;
            color: $white;
          };
        }

        .button {
          font-weight: 700;
          font-size: 14px;
          padding: 22px 5px;
          border: none;
        }
      }
    }
  }
}
