@import '../../../styles/colors';

.stakeholderContainer {
  display: flex;
  margin-bottom: 4px;

  .stakeholder {
    width: 50%;
    display: flex;
    margin-right: 15px;

    .segmentsInputContainer {
      width: 100%;
      position: relative;

      .segmentsInput {
        margin: 0 8px 0 1px;
        input {
          font-weight: 500;
          font-size: 14px;
          padding-right: 35px;
        }
      }

      .tipSegment {
        position: absolute;
        top: 0;
        right: 12px;
        transform: translateY(25%);
        z-index: 1;
      }
    }

    .segmentsSelectInput {
      margin: 0 0 0 8px;

      .buttonActionContainer {
        margin: 16px;

        .button {
          box-shadow: none;
          background: $lightBlue;
          border: 1px solid $lightGray2;
          border-radius: 4px;
          padding: 12px 10px;
          color: #363A42;
          font-weight: 600;
          font-size: 12px;

          svg {
            margin-right: 12px;
          }
        }
      }

      input {
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
        &::placeholder {
          text-transform: capitalize;
        }
      }
    }
  }

  .stakeholderSelectInput {
    width: 50%;
    margin: 0 0 0 15px;

    input {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .customer {
    width: 50%;
    margin: auto 0 auto 15px;
    font-weight: 500;
    font-size: 14px;
  }
}
