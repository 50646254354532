@import '../../../styles/colors';

.linkModal {
  margin-top: 16px;
}

.linkModalButtonContainer {
  margin-bottom: 0;
  .linkModalButton {
    box-shadow: none;
    background-color: $blue1;
    text-transform: uppercase;
  }
}