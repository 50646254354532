@import '../../../styles/colors';

.container {
  top: auto !important;
  bottom: 110% !important;

  .triangle {
    transform: translateX(-50%) rotateX(180deg);
    top: 100%;
  }
}

.containerSOM {
  left: -250% !important;

  .triangle {
    left: 71%;
  }
}
