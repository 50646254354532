@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 25px 1px;
    min-width: 800px;

    .headerContainer {
      display: flex;
      margin-bottom: 16px;

      .titleContainer {
        flex: 1;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .titleWrapper {
          display: flex;
          flex-direction: column;

          .title {
            justify-content: flex-start;
            background: none;
            border: none;
            padding: 0;

            .titleText {
              margin-right: 8px;
              font-weight: 600;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-transform: capitalize;
            }

            .tipTextContainer {

              left: 500% !important;

              .triangle {
                left: 18%;
              }
            }
          }

          .subtitle {
            font-weight: 400;
            font-size: 13px;
            line-height: 1;
            color: $gray;
            text-transform: capitalize;
            padding-right: 8px;
          }
        }

        .referenceContainer {
          margin: 0;

          .referenceWrapper {
            width: 100%;
          }
        }
      }

      .selectContainer {
        flex: 1;
        margin: 0 0 0 15px;

        input {
          font-weight: 500;
          font-size: 14px;
          color: $black;
          text-transform: capitalize;
        }

        .selectInputItem {
          text-transform: capitalize;
        }
      }
    }

    .trendsItemHeaderContainer {
      display: flex;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 15px;

      .trendsItemHeaderValues {
        flex: 1;
        margin-right: 15px;
        display: flex;

        .trend {
          flex: 1;
          margin-right: 8px;
          display: flex;
          padding: 0;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
        }

        .influence {
          width: 99px;
          margin-right: 8px;
          display: flex;
          padding: 0;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
        }

        .rank {
          width: 90px;
          display: flex;
          padding: 0;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
        }
      }

      .trendsItemHeaderMessage {
        flex: 1;
        margin-left: 15px;
      }
    }

    .trendItemContainer {
      display: flex;
      margin-bottom: 16px;
      align-items: center;

      .trendItemValuesContainer {
        flex: 1;
        margin-right: 15px;
        display: flex;

        .inputContainer {
          flex: 1;
          margin: 0 8px 0 0;

          input {
            font-weight: 500;
            font-size: 14px;
            color: $black;
          }
        }

        .selectContainer {
          flex: 1;
          max-width: 99px;
          margin: 0 8px 0 0;

          input {
            font-weight: 500;
            font-size: 14px;
            color: $black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: capitalize;
          }

          .selectInputItem {
            text-transform: capitalize;
          }
        }

        .selectContainerRight {
          max-width: 90px;
          margin-right: 0;
        }
      }

      .trendItemDescriptionContainer {
        flex: 1;
        margin-left: 15px;
        display: flex;

        .inputContainer {
          flex: 1;
          margin: 0;

          input {
            font-weight: 500;
            font-size: 14px;
            color: $black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .trendButtonContainer {
      margin: 0;

      .button {
        box-shadow: none;
        background: #DDE9FF;
        border: 1px solid #3179C1;
        border-radius: 4px;
        color: #3179C1;
        font-weight: 600;
        font-size: 12px;
        padding: 13px 0;

        svg {
          margin-right: 12px;
        }
      }
    }
  }
}
