@import '../../../styles/colors';

.container {
  position: relative;
  cursor: pointer;
  width: 193px;
  height: 193px;
  margin: 12px;
  background-color: $white;
  border: 1px solid #D7E2F0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.21);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  &.containerAddCard {
    background-color: $lightBlueOpacity;

    .text {
      color: $white;
    }
  }

  &:hover .edit {
    display: inline;
  }

  .imgContainer {
    display: flex;
    justify-content: center;
    height: 56%;
    width: 80%;

    .avatar {
      width: 100%;
      height: 110%;
      object-fit: cover;
      border-radius: 8px;
      margin-top: 8px;
    }

    &.addCardImg {
      padding-top: 25px;
    }
  }

  .edit {
    position: absolute;
    top: 6px;
    right: 10px;
    display: none;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 0;

    .editButton {
      color: $blue1;
      padding: 6px 10px;
      font-weight: 700;
      font-size: 13px;

      &:hover {
        opacity: 0.9;
      }
    }

    svg {
      margin-right: 8px;
    }
  }

  .text {
    font-weight: 700;
    font-size: 14px;
    padding: 0 10px;
    width: 100%;
    text-align: center;
    color: $blue1;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
