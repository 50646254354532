@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentWrapper {
    margin: 40px 0 140px 0;
    min-width: 1000px;

    .titleContainer {
      width: max-content;
      background: none;
      border: none;

      .title {
        font-weight: 600;
        color: $gray3;
        padding-right: 8px;
      }
    }

    .content {
      display: flex;

      .chartContainer {
        height: 670px;
        width: 650px;
        padding-top: 20px;
        position: relative;
        margin-top: 32px;

        .chart {
          height: 650px;
          width: 620px;
          background: $grayOpacity2;
          display: flex;
          align-items: flex-end;
          padding-bottom: 30px;

          .customLabelYContainer {
            height: 400px;
            position: relative;

            .labelContainer {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              height: 100%;
              cursor: pointer;

              .driver {
                writing-mode: vertical-rl;
                transform: rotate(180deg);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: $lightBlueOpacity3;
                font-weight: 600;
                font-size: 16px;
                cursor: pointer;
              }

              img {
                cursor: pointer;
                width: fit-content;
              }
            }

            .driversList {
              position: absolute;
              max-height: 400px;
              width: 410px;
              left: 60px;
              top: -1px;
              overflow: hidden;
              border-radius: 6px;
              display: none;
            }

            .openDriversList {
              display: block;
            }
          }

          .customLabelXContainer {
            width: 410px;
            position: relative;
            overflow: visible;

            .labelContainer {
              display: flex;
              justify-content: space-between;
              cursor: pointer;

              .driver {
                color: $lightBlueOpacity3;
                font-weight: 600;
                font-size: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
              }

              img {
                cursor: pointer;
              }
            }

            .driversList {
              position: absolute;
              bottom: 45px;
              max-height: 400px;
              width: 100%;
              overflow: hidden;
              border-radius: 6px;
              display: none;
            }

            .openDriversList {
              display: block;
            }
          }

          .driversContainer {
            max-height: 400px;
            height: 100%;
            overflow: scroll;
          }

          .customLabelItem {
            text-align: center;
            padding: 10px 16px;
            font-weight: 500;
            color: $lightBlue2;
            background: $lightBlue;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover {
              font-weight: 600;
              background: $lightGray2;
              color: $black;
            }
          }

          .active {
            font-weight: 600;
            background: $lightGray2;
            color: $black;
          }

          .tooltip {
            transform: translate(-50%, -200%);
            font-weight: 700;
            font-size: 16px;
            max-width: 146px;
            color: $lightBlue3;
            position: relative;

            .containerTooltip {
              position: absolute;
              bottom: 20px;
              width: 146px;
              transform: translate(-50%, 0);
              .name {
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;

                &:not(:last-child) {
                  margin-bottom: 4px;
                }
              }

              .ownOrganization {
                color: $green3;
              }
            }

          }
        }

        .topArrow {
          position: absolute;
          top: 0;
          left: 0;
          right: 30px;
          text-align: center;
          .arrow {
            position: relative;

            span {
              display: inline-block;
              transform: rotate(-90deg);
              color: $red4;
              cursor: pointer;
            }

            &:before {
              content: '';
              position: absolute;
              top: 0;
              height: 20px;
              left: 14px;
              right: -15px;
              z-index: 0;
              background: $lightGray7;
              transform: skew(-56deg);
              cursor: pointer;
            }
          }
        }

        .rightArrow {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 30px;

          span {
            display: inline-block;
            z-index: 1;
            cursor: pointer;
          }

          .arrow {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
              content: '';
              top: 10px;
              bottom: 10px;
              width: 30px;
              position: absolute;
              z-index: 0;
              background: $grayOpacity3;
              transform: skewY(-34deg);
              cursor: pointer;
            }
          }
        }
      }

      .descriptionContainer {
        flex: 1;
        padding-left: 38px;
        padding-right: 1px;

        .selectInput {
          margin-bottom: 24px;

          input {
            font-weight: 500;
            font-size: 14px;
            padding: 11px 15px;
          }
        }

        .grey {
          input {
            color: $lightBlue3
          }
        }

        .input {
          margin-bottom: 27px;
          input {
            font-weight: 500;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 11px 15px;
          }
        }

        .line {
          height: 1px;
          width: 100%;
          background: $lightGray2;
          margin-bottom: 24px;
        }
      }
    }
  }
}
