@import '../../../styles/colors';

.container {
  display: flex;
  justify-content: center;
  margin: 42px 0;
  .logo {
    width: 190px;
    height: 160px;
    background: $white;
    border: 1px solid #D7E2F0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.21);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    .imageUpload {
      display: none;
    }
  }
}
