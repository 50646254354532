@import '../../../styles/colors';

.container {
  margin-bottom: 16px;

  button {
    width: 100%;
    border: 1px solid $white;
    border-radius: 8px;
    font-weight: 700;
    color: $lightBlue;
    padding: 20px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.21);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover, &:disabled {
      opacity: 0.7;
    }
  }
}
