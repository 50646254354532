@import '../../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    min-width: 950px;
    padding: 0 1px;

    .logo {
      width: 144px;
      height: 144px;
      border-radius: 50%;
      border: 20px solid $blue5;
      margin: 33px 0 19px 0;
    }

    .scenarioBackButtonContainer {
      align-self: flex-start;
      .scenarioBackButton {
        box-shadow: none;
        color: $gray3;
        font-weight: 600;
        font-size: 12px;
        padding: 0;
        text-transform: capitalize;
        svg {
          margin-right: 12px;
        }
      }
    }

    .title {
      color: $black;
      font-weight: 700;
      font-size: 19px;
      text-transform: uppercase;
      line-height: 19px;
      margin-bottom: 37px;
    }

    .subtitle {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      .subtitleText {
        color: $black;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 17px;
        margin: 0;
      }

      .buttonContainer {
        margin-bottom: 0;
        .button {
          color: $black;
          font-weight: 600;
          font-size: 12px;
          padding: 12px 24px;
          box-shadow: none;
          background: #F9FBFF;
          border: 1px solid #E2E6ED;
          border-radius: 4px;

          svg {
            margin-right: 12px;
          }
        }
      }
    }

    .filterContainer {
      display: flex;
      width: 100%;
      margin-bottom: 37px;
      padding: 0 1px;

      .filter {
        margin: 0 10px 0 0;

        input {
          background: $lightBlue;
          border-color: $lightGray2;
          font-weight: 400;
          font-size: 14px;

          &::placeholder {
            color: $gray;
          }
        }

        .filterDropDown {
          text-transform: capitalize;
        }
      }

      .selectInput {
        input {
          text-transform: capitalize;
        }
      }

      .buttonFilterContainer {
        margin: 0 0 0 10px;

        .buttonFilter {
          color: $white;
          font-weight: 400;
          font-size: 14px;
          background: $blue5;
          padding: 0 15px;
          height: 100%;
          box-shadow: none;
        }
      }
    }

    .trends {
      width: 100%;
      margin-bottom: 60px;

      .row {
        display: flex;
        padding: 4px 10px;
        font-weight: 400;
        font-size: 14px;
        color: $gray;

        button {
          color: $gray;
          &:disabled {
            color: $gray;
            cursor: default;
          }
        }

        .column {
          padding: 11px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-transform: capitalize;
          position: relative;
        }

        .header {
          display: flex;
          align-items: center;
        }

        .createDate {
          flex: 0 0 110px;
          padding-right: 20px;
          text-align: center;
        }

        .industry {
          flex: 1;
          min-width: 175px;
          max-width: 230px;
          padding-right: 20px;
        }

        .category {
          flex: 1;
          min-width: 100px;
          max-width: 200px;
          padding-right: 20px;
        }

        .trend {
          flex: 1;
          min-width: 226px;
          padding-right: 20px;
        }

        .influence {
          flex: 1;
          min-width: 92px;
          max-width: 120px;
          padding-right: 30px;
        }

        .rank {
          flex: 0 0 68px;
          padding-right: 35px;
          text-align: center;
        }

        .actions {
          flex: 0 0 62px;
          padding: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .buttonActionContainer {
            margin-bottom: 0;

            .button {
              padding: 6px;
              background: $white;
              border: 1px solid #D7E2F0;
              border-radius: 3px;
              box-shadow: none;
            }
          }
        }
      }
      .line {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        color: $black;
        padding: 0 10px;
        .email {
          color: $blue5
        }
      }
    }
  }
}
