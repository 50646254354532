@import '../../../styles/colors';

.container {
  margin-top: 16px;
  width: 540px;

  .text {
    font-weight: 500;
    font-size: 14px;
    color: $lightBlue;
    text-align: center;
    margin-bottom: 7px;
  }

  .resend {
    text-align: center;
    font-size: 14px;
    color: $lightBlue;
    font-weight: 300;
    margin-bottom: 34px;

    span {
      color: $white;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.buttonContainer {
  button {
    background: $green;
    text-transform: uppercase;
  }
}

.buttonCancel {
  margin-bottom: 0;

  button {
    background-color: $red;
    text-transform: uppercase;
  }
}
