@import '../../../styles/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 42px;

  span {
    font-weight: 600;
  }

  .inputContainer {
    margin-top: 42px;
  }

  .summaryContainer {
    display: flex;
    width: 100%;
    text-align: left;

    .summaryRadio {
      width: 50%;
    }

    .summaryTypesDocument {
      width: 50%;
      div {
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }

  .allDataContainer {
    display: flex;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    div {
      width: 50%;
    }
  }

  .buttonContainer {
    width: 100%;
    margin: 60px 0 0 0;
    .button {
      background: $blue2;
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
}
