.container {
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding: 0;
}

.up {
  transform: rotate(180deg);
}
