@import '../../../styles/colors';

.container {
  width: 100%;
  margin-bottom: 16px;
  position: relative;

  .label {
    font-weight: 600;
    font-size: 14px;
    color: $gray3;
    line-height: 15px;
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 16px;
  }

  .inputContainer {
    position: relative;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    .icon {
      padding: 0;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-40%);
      background: none;
      border: none;
      outline: none;
    }
  }

  input {
    border-radius: 4px;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid $lightGray2;
    background: $lightBlue;
    font-size: 13px;
    box-sizing: border-box;
    color: $black;
    &::placeholder {
      color: $gray;
    }
    &:focus {
      outline: 1px solid $blue1;
    }
  }

  .error {
    padding-left: 16px;
    padding-top: 10px;
    font-size: 13px;
    color: $red
  }
}
