@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 0 0 48px 0;
    min-width: 900px;

    .M2ItemContainer {
      margin-top: 32px;

      .titleContainer {
        width: calc(50% - 15px);

        .title {
          font-weight: 400;
          color: $gray;
        }
      }

      .content {
        display: flex;
        margin-top: 16px;

        .avatarContainer {
          width: 190px;
        }

        .itemContainer {
          display: flex;
          margin-bottom: 16px;

          .itemInput {
            margin-bottom: 0;
            width: 75%;
            margin-right: 8px;

            input {
              font-weight: 500;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .itemSelectInput {
            width: 25%;
            min-width: 78px;
            margin-bottom: 0;

            input {
              font-weight: 500;
              font-size: 14px;
            }
          }
        }

        .driverContainer {
          flex: 1;
          margin-right: 15px;

          .itemContainer {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .stakeholderContainer {
          flex: 1;
          margin-left: 15px;

          .itemContainer {
            &:last-child {
              margin-bottom: 0;
            }
          }

          .stakeholderSelectInput {
            width: 75%;
            margin-right: 8px;
          }
        }

        .profilesContainer {
          width: 190px;
          padding-left: 30px;
          display: flex;
          flex-direction: column;

          .profilesHeader {
            display: flex;
            align-items: center;

            .profilesTitle {
              font-weight: 600;
              font-size: 14px;
              line-height: 14px;
              padding: 0;
              margin: 0 5px 0 0;
              color: #363A42;
            }

            .tipDetailedProfiles {
              .tipTextContainer {
                left: -560%;
              }

              .triangle {
                left: 93%;
              }
            }
          }

          /* width */
          ::-webkit-scrollbar {
            width: 2px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            border-radius: 2px;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: $gray;
            border-radius: 2px;
          }

          .profilesContent {
            flex: 1;

            .profilesDetailedContainer {
              margin-top: 2px;
              height: 185px;
              overflow-y: auto;

              .profilesDetailedName {
                display: block;
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 4px;
                color: $blue1;
                cursor: pointer;
                text-decoration: none;
              }
            }
          }

          .profilesButtonContainer {
            margin-bottom: 0;
            margin-top: 23px;

            .profilesButton {
              box-shadow: none;
              background: $lightGray5;
              border: 1px solid $blue1;
              border-radius: 4px;
              padding: 12px 0;
              color: $blue1;
              font-weight: 600;
              font-size: 12px;

              svg {
                margin-right: 12px;
              }
            }
          }
        }
      }

      .availableContainer {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        gap: 15px;
        border-bottom: 1px solid $lightGray2;

        .availableItem {
          .availableItemTitleContainer {
            display: flex;
            align-items: center;
            height: 41px;

            .availableItemTitleWrapper {
              flex: 1;
              display: flex;
              margin-right: 8px;
              align-items: center;

              .availableItemTitle {
                color: $gray3;
                font-weight: 600;
                font-size: 15px;
                line-height: 15px;
                margin-right: 8px;
              }
            }

            .availableItemInputContainer {
              width: 70px;
              padding-right: 1px;
              position: relative;
              .availableItemInput {
                margin-bottom: 0;

                input {
                  font-weight: 500;
                  font-size: 14px;
                  text-align: center;
                  padding: 12px 17px 12px 10px;
                }
              }

              span {
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translateY(-52%);
                font-weight: 500;
                font-size: 14px;
              }
            }
          }
        }

        .availableItemCalculation {
          display: flex;
          margin-top: 11px;
          margin-bottom: 26px;
          justify-content: space-between;
          gap: 24px;

          .availableItemCalculationInputContainer {
            position: relative;
            flex: 1 1 125px;

            .availableItemCalculationInput {
              margin-bottom: 0;
              padding-left: 1px;
              input {
                padding-left: 28px;
                font-weight: 500;
                font-size: 14px;
              }
            }

            span {
              position: absolute;
              top: 27%;
              left: 12px;
            }
          }

          .currency {
            flex: 1 1 180px;
          }

          .tam {
            flex: 0 0 125px;
          }
        }
      }
    }
  }
}
