$white: #fff;
$gray: #8891A3;
$gray2: #44474B;
$gray3: #363A42;
$grayOpacity: rgba(211, 211, 211, 0.8);
$grayOpacity2: rgba(196, 196, 196, 0.2);
$grayOpacity3: rgba(155, 154, 154, 0.4);
$lightGray: #FCFCFC;
$lightGray2: #E2E6ED;
$lightGray3: #F8FBFF;
$lightGray4: #D7E2F0;
$lightGray5: #DDE9FF;
$lightGray6: #F5F5F5;
$lightGray7: #E9E6E6;
$lightBlueOpacity: rgba(233, 241, 253, 0.2);
$lightBlueOpacity2: rgba(199, 229, 253, 0.6);
$lightBlueOpacity3: rgba(81, 94, 113, 0.5);
$lightBlue: #F9FBFF;
$lightBlue1: #E9F1FD;
$lightBlue2: #646D77;
$lightBlue3: #515E71;
$black: #000;
$blackOpacity: rgba(0, 0, 0, 0.3);
$blackOpacity2: rgba(0, 0, 0, 0.1);
$blackOpacity3: rgba(0, 0, 0, 0.21);
$blue1: #3179C1;
$blue2: #2E70BA;
$blue3: #418ECB;
$blue4: #2F75BD;
$blue5: #1684C8;
$blue6: #133A85;
$blue7: #1786CA;
$blue8: #1370BD;
$blue9: #178ACC;
$blue10: #188BCD;
$blue11: #213BFF;
$green: #51A253;
$green1: #1EB262;
$green2: #15B82F;
$green3: #00C337;
$greenOpacity: rgba(30, 178, 98, 0.1);
$red: #ED6262;
$red1: #EF4A4A;
$red2: #DC7B7B;
$red3: #F80A0A;
$red4: #71515F;
$orange: #FF9E1A;
$orange2: #FF6B00;

