@import '../../../styles/colors';

.triggersContainer {
  display: flex;
  width: 100%;
  margin-bottom: 41px;

  .buttonContainer {
    width: 160px;
    margin: auto 30px 4px 0;
    visibility: hidden;

    button {
      margin-left: 2px;
      background: $red2;
      box-shadow: 0 4px 10px $blackOpacity3;
      border-radius: 8px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 10px;
    }
  }

  .buttonContainerVisible {
    visibility: visible;
  }

  .triggersWrapper {
    display: flex;
    width: calc(100% - 191px);

    .triggersItemContainer {
      width: 33.3%;
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }

      .triggersItemTitleContainer {
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .triggersItemTitle {
          font-weight: 600;
          font-size: 15px;
          line-height: 17px;
          color: $black;
          display: flex;
          align-items: center;
          margin-right: 7px;
        }
      }

      textarea {
        border-radius: 4px;
        padding: 11px 15px;
        border: 1px solid $lightGray2;
        background: $lightBlue;
        font-size: 13px;
        box-sizing: border-box;
        color: $black;
        margin-bottom: 0;
        resize: none;
        &::placeholder {
          color: $gray;
          letter-spacing: -0.5px;
        }
      }
    }
  }
}
