@import '../../../styles/colors';

.text {
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: $lightBlue;
}

.noVisible {
  visibility: hidden;
}
