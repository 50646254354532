@import '../../../styles/colors';

.container {
  width: 160px;
  height: 155px;
  background: $lightGray3;
  border: 1px solid $lightGray4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    color: $gray;
    text-align: center;
    margin-top: 10px;
  }

  input {
    display: none;
  }
}

.containerWithUrl {
  background: transparent;
  border-color: transparent;
}

.cursorPointer {
  cursor: pointer;
}
