@import '../../../styles/colors';

.container {
  position: relative;
  input {
    position: absolute;
    width: 22px;
    height: 22px;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
  }
  label {
      color: $gray;
      font-weight: 400;
      font-size: 13px;
      &:before {
        content: '';
        background: $white;
        border-radius: 100%;
        border: 1px solid $lightGray2;
        display: inline-block;
        width: 19px;
        height: 19px;
        position: relative;
        top: 0;
        margin-right: 9px;
        vertical-align: top;
        text-align: center;
        transition: all 250ms ease;
      }
  }

  input:checked + label {
    color: $blue8;
    &:before {
      background-color: $blue8;
      box-shadow: inset 0 0 0 4px #f4f4f4;
    }
  }

  input:disabled + label:before {
    background-color: $lightGray2;
    border-color: $grayOpacity
  }
}
