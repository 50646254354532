@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 21px 0 100px 0;
    min-width: 1100px;

    .headerTitle {
      font-size: 14px;
      font-weight: 600;
      color: #363A42;
      line-height: 14px;
      margin-bottom: 14px;
      text-transform: capitalize;
    }

    .headerAction {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;

      .selectContainer {
        flex: 0 0 266px;
        margin-bottom: 0;

        input {
          font-weight: 500;
          font-size: 14px;
          padding-top: 11px;
          padding-bottom: 11px;
          text-transform: capitalize;
        }
      }

      .buttonWithTip {
        display: flex;
        align-items: center;

        .buttonContainer {
          margin: 0 8px 0 0;
          width: 134px;

          .button {
            color: $blue1;
            padding: 12px 0;
            font-size: 12px;
            font-weight: 600;
            box-shadow: none;
            background: $lightGray5;
            border: 1px solid $blue1;
            border-radius: 4px;

            svg {
              margin-right: 8px;
            }
          }
        }

        .tipTextContainer {
          left: -640%;

          .triangle {
            left: 97%
          }
        }
      }
    }
  }

  .tipTextLegendContainer {
    top: auto !important;
    bottom: 110% !important;

    .triangle {
      transform: translateX(-50%) rotateX(180deg);
      top: 100%;
    }
  }
}
