.pageContainer {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

    .wrapper {
      margin: 80px 0 40px;
      max-width: 868px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
}
