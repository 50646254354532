@import '../../../styles/colors';

.header {
  padding: 35px 0 31px 0;
  background-color: #fff;
  .container {
    display: flex;
    justify-content: space-between;

    .buttonsActionContainer {

      display: flex;
      align-items: center;

      .buttonContainer {
        margin: 0;

        &:not(:last-child) {
          margin-right: 8px;
        }

        .button {
          background: $blue3;
          padding: 8px 15px;
          border-radius: 4px;
          text-transform: capitalize;
          border: none;
          font-size: 14px;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
