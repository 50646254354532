@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .content {
    margin: 30px 0 48px 0;
    min-width: 900px;
    position: relative;

    .tipOrganization {
      display: inline-block;
      padding-left: calc(50% - 47px);
    }

    .coreMeaningContainer {
      display: flex;
      width: 100%;
      margin-bottom: 26px;
    }
  }
}
