@import '../../../styles/colors';

.icon {
  padding: 26px 0;
  display: block;
  margin: 0 auto;
}

.loader {
    width: 69px;
    height: 69px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 26px auto;
    img {
      width: 60px;
      height: 60px;
    }
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.error {
  text-align: center;
  margin-bottom: 0;
}

.button {
  margin-bottom: 0;
  button {
    background: $green;
    text-transform: uppercase;
  }
}
