@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 32px 0 67px 0;
    min-width: 800px;

    .itemContainer {
      padding: 0 1px 32px 0;
      border-bottom: 1px solid $lightGray2;

      &:not(:last-child) {
        margin-bottom: 32px;
      }

      .title {
        width: 50%;
        margin-bottom: 16px;
      }

      .content {
        display: flex;

        .offeringContainer {
          flex: 1;
          margin-left: 30px;

          .offering {
            display: flex;

            &:not(:last-child) {
              margin-bottom: 16px;
            }

            .offeringInput {
              margin-bottom: 0;
              input {
                font-weight: 500;
                font-size: 14px;
                padding: 11px 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .offeringSelect {
              margin: 0 0 0 8px;
              width: 92px;
              input {
                font-weight: 500;
                font-size: 14px;
                padding: 11px 15px;
              }
            }
          }
        }
      }
    }
  }
}
