@import '../../../styles/colors';

.container {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 18px;

  .input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .switcher {
      &:before {
        left: 12px;
        background-color: $green;
      }
    }
  }

  .switcher {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $lightBlue;
    border-radius: 10px;
    border: 1px solid $lightGray4;

    &:before {
      position: absolute;
      content: "";
      height: 10px;
      width: 10px;
      left: 3px;
      top: 50%;
      transform: translateY(-49%);
      background-color: $gray;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 5px;
    }
  }
}
