@import '../../../styles/colors';

.coreMeaningWrapper {
  width: calc(100% - 190px);

  .coreMeaning {
    width: calc(100% - 1px);
    display: flex;
    margin-bottom: 16px;

    .coreMeaningSelectWrapper {
      display: flex;
      flex: 1 1 calc(50% - 190px);
      padding-right: 15px;
      width: 100%;

      .coreMeaningSelectContainer {
        width: 75%;
        margin-bottom: 0;
        margin-right: 8px;

        input {
          overflow: hidden;
          font-size: 14px;
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .coreMeaningRateContainer {
        width: 25%;
        min-width: 72px;
        margin-bottom: 0;

        input {
          font-size: 14px;
          font-weight: 500;
        }

        .coreMeaningRateDropDown {}
      }
    }

    .coreMeaningTypeContainer {
      flex: 1 1 50%;
      padding-left: 15px;

      .coreMeaningType {
        margin-bottom: 0;

        input {
          font-size: 14px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.line {
  height: 1px;
  background-color: $lightGray2;
  margin-top: 30px;
}
