@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 27px 1px 100px 1px;
    min-width: 1000px;

    .header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;

      .buttonContainer {
        margin: 0;

        .button {
          color: $blue1;
          padding: 12px 11px;
          font-size: 12px;
          font-weight: 600;
          box-shadow: none;
          background: $lightGray5;
          border: 1px solid $blue1;
          border-radius: 4px;

          svg {
            margin-right: 8px;
          }
        }
      }

      .tipTextContainer {
        left: -640%;

        .triangle {
          left: 97%
        }
      }
    }
  }
}
