@import '../../../styles/colors';

.container {
  position: relative;

  .datePicker {
    border-radius: 4px;
    min-width: 220px;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid $lightGray2;
    background: $lightBlue;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    color: $black;

    &:focus {
      outline: 1px solid $blue1;
    }
  }

  .closeIcon {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
