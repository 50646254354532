.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: white;
}

.pageBlue {
  background: linear-gradient(180deg, #3179C1 0%, #255AA6 100%);
}
