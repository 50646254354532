@import '../../../styles/colors';

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $blackOpacity;
  z-index: 10;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    flex-flow: row wrap;
    padding: 0 20px;

    .wrapper {
      width: 634px;
      text-align: center;
      margin: 30px 0;
      background: $lightBlue1;
      border: 1px solid rgba(255, 255, 255, 0.15);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.21);
      border-radius: 8px;

      .content {
        padding: 40px 45px;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 33px;

          .title {
            color: $black;
          }

          .icon {
            cursor: pointer;
          }
        }
      }
    }
  }
}
