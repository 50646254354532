@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;
}

.organizationBlock {
  width: 310px;
  height: 275px;
  background: $lightBlueOpacity2;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  div {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    padding: 20px;
    color: $blue6
  }
}

.supplyContainer {
  display: flex;
  border-radius: 12px;
  margin-top: -8px;
  padding-bottom: 27px;
  position: relative;

  .stakeholdersBlock {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 73%;
    background: $grayOpacity;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    border-radius: 13px;
  }

  .nameAbsolute {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);

    h4 {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      color: #554655;
    }
  }

  .name {
    flex: 1;
    align-self: stretch;
    max-width: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    color: $gray2;
  }

  h4 {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
  }

  .supplyRows {
    display: flex;
    flex-direction: column;
    flex: 1;

    .row {
      margin-bottom: 0;

      .itemContainer {
        padding: 7px 0;
        margin: 0 2px;
        position: relative;

        .item {
          color: $black;
          font-weight: 500;
          position: relative;
        }
      }

      &:last-child {
        .itemContainer {}
      }

      &:nth-child(3) {
        .itemContainer {
          &:nth-child(5) {
            position: relative;
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 18px;
              border-radius: 0 0 13px 13px;
              bottom: -18px;
              left: 0;
              background: rgba(255,255,255, 0.3);
            }
          }
        }
      }

      &:nth-child(5) {
        .itemContainer {
          &:nth-child(4) {
            position: relative;
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 18px;
              border-radius: 0 0 13px 13px;
              bottom: -18px;
              left: 0;
              background: rgba(255,255,255, 0.3);
            }
          }
        }
      }

      &:nth-child(6) {
        .itemContainer {
          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            position: relative;
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 18px;
              border-radius: 0 0 13px 13px;
              bottom: -18px;
              left: 0;
              background: rgba(255,255,255, 0.3);
            }
            &:before {
              z-index: 1;
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              content: 'SUPPLY CYCLE';
              color: #44474B;
              position: absolute;
              width: 100%;
              height: 18px;
              border-radius: 0 0 13px 13px;
              bottom: -18px;
              left: 0;
            }

          }

          &:nth-child(2), &:nth-child(3) {
            &:before {
              content: '';
            }
          }
        }
      }
    }
  }
}

.content {
  position: relative;
  width: 1160px;
  margin: 50px 0;
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    flex: 1;
    margin-right: 50px;

    .name {
      flex: 1;
      align-self: stretch;
      max-width: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 13px 0 0 13px;

      h4 {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: $gray2;
      }
    }

    .itemContainer {
      display: flex;
      justify-content: center;
      padding: 12px 0;
      border: 0;
      flex: 1;
      position: relative;

      &.empty {
        visibility: hidden;
      }

      .item {
        background: $white;
        border: 1px solid $white;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.21);
        border-radius: 8px;
        width: 118px;
        height: 63px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: $blue6;
        font-size: 14px;
        font-weight: 700;
        z-index: 1;
        cursor: pointer;
        text-align: center;
      }

      .visible {
        opacity: 0.3;
      }
    }
  }
}

.guideContainer {
  position: absolute;
  top: 86px;
  width: 240px;
  height: 100px;
  background: black;
  z-index: 2;
  border: 1px solid #E2E6ED;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  .guideContent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    padding: 25px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    .guideTriangle {
      position: absolute;
      top: -7px;
      left: 50%;
      border-left: 1px solid #E2E6ED;
      border-top: 1px solid #E2E6ED;
      width: 12px;
      height: 12px;
      background: black;
      transform: rotate(45deg);
    }

    .closeGuide {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      z-index: 2;
      padding: 0;
      background: none;
      color: white;
    }
  }
}

.wrapperButton {
  position: relative;

  .buttonIcon {
    position: absolute;
    top: -7px;
    right: -8px;
    z-index: 3;
  }
}
