@import '../../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    overflow: auto;
    padding: 0 1px;
    min-width: 950px;

    .logo {
      height: 144px;
      width: 144px;
      border-radius: 50%;
      margin: 33px 0 19px 0;
      border: 20px solid $blue5;
    }

    .title {
      color: $black;
      font-weight: 700;
      font-size: 19px;
      text-transform: uppercase;
      line-height: 19px;
      margin-bottom: 37px;
    }

    .subtitle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      .subtitleText {
        color: $black;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 17px;
        margin: 0;
      }

      .buttonContainer {
        margin-bottom: 0;
        .button {
          color: $black;
          font-weight: 600;
          font-size: 12px;
          padding: 12px 24px;
          box-shadow: none;
          background: $lightBlue;
          border: 1px solid $lightGray2;
          border-radius: 4px;

          svg {
            margin-right: 12px;
          }
        }
      }
    }

    .filterContainer {
      display: flex;
      width: 100%;
      margin-bottom: 37px;
      padding: 0 1px;

      .filter {
        margin: 0 10px 0 0;
        width: 30%;

        input {
          background: $lightBlue;
          border-color: $lightGray2;
          font-weight: 400;
          font-size: 14px;

          &::placeholder {
            color: $gray;
          }
        }
      }

      .buttonFilterContainer {
        margin: 0 0 0 10px;

        .buttonFilter {
          color: $white;
          font-weight: 400;
          font-size: 14px;
          background: $blue5;
          padding: 0 15px;
          height: 100%;
          box-shadow: none;
        }
      }
    }

    .scenarios {
      width: 100%;
      margin-bottom: 60px;

      .row {
        display: grid;
        grid-template-columns: 110px 145px 1fr 90px 138px 96px;
        font-weight: 400;
        padding: 9px 10px;
        color: $gray;
        font-size: 14px;

        button {
          color: $gray;
          &:disabled {
            color: $gray;
            cursor: default;
          }
        }

        .column {
          display: flex;
          align-items: center;
          padding: 11px 10px 11px 0;
          overflow: hidden;
          text-transform: capitalize;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: capitalize;
          }
        }

        .description {
          padding-right: 50px;
        }

        .link {
          color: $blue10;
          text-decoration: none;
        }

        .header {
          display: flex;
          align-items: center;
        }

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0;

          .buttonActionContainer {
            margin-bottom: 0;

            .buttonAction {
              padding: 6px;
              background: $white;
              border: 1px solid $lightGray4;
              border-radius: 3px;
              box-shadow: none;
            }
          }
        }
      }

      .line {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 10px;
        color: $black;

        .column:first-child {
          padding-left: 5px;
        }
      }
    }
  }
}
