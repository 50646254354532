@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 40px 0;
    min-width: 1000px;
    padding: 0 1px;

    .headerWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .header {
        width: calc(50% - 15px);
        margin: 0 10px 0 0;
        input {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .opportunityContainer {
      border-top: 1px solid $lightGray2;
      padding: 10px 1px 0 1px;
      .headerContainer {
        width: calc(50% - 15px);
        margin-bottom: 32px;

        .title {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #363A42;
          margin-bottom: 7px;
        }

        .wrapper {
          display: flex;
          .headerInput {
            margin-bottom: 0;
            margin-right: 8px;
            input {
              font-weight: 400;
              font-size: 14px;
            }
          }

          .headerSelect {
            flex: 20%;
            min-width: 72px;
            margin-bottom: 0;
            input {
              font-weight: 400;
              font-size: 14px;
            }
          }

          .headerSelectMeaning {
            flex: 100%;
            margin-right: 8px;
          }
        }
      }

      .offeringTitleContainer {
        display: flex;
        margin-bottom: 16px;

        &:last-child {
          margin: 0 0 0 15px;
        }

        .title {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #363A42;
          flex: 1;
          margin: 0 15px 0 0;
          &:last-child {
            margin: 0 0 0 15px;
          }
        }
      }

      .offeringContainer {
        display: flex;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 33px;
        }

        .potentialCustomer {
          flex: 1;
          margin-right: 15px;
          display: flex;

          .input {
            margin-bottom: 0;
            margin-right: 8px;
            input {
              font-weight: 500;
              font-size: 14px;
            }
          }

          .select {
            flex: 20%;
            min-width: 72px;
            margin-bottom: 0;
            input {
              font-weight: 500;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .potentialMessaging {
          flex: 1;
          margin: 0 0 0 15px;
          input {
            font-weight: 500;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .buttonContainer {
        margin-bottom: 30px;

        .button {
          font-weight: 600;
          font-size: 12px;
          color: $blue1;
          box-shadow: none;
          background: $lightGray5;
          border: 1px solid $blue1;
          border-radius: 4px;
          padding: 12px;

          svg {
            margin-right: 12px;
          }
        }
      }
    }
  }
}
