@import '../../../styles/colors';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 14px 5px 16px;
  background: $lightBlue;
  border: 1px solid $lightGray2;
  border-radius: 4px;

  .title {
    font-weight: 500;
    font-size: 14px;
    color: $black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 5px;
  }
}
