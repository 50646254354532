@import '../../../styles/colors';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 150px 0;

  .text {
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
    color: $red1;
  }

  .buttonContainer {
    margin: 70px 0 0 0;

    .button {
      font-weight: 600;
      font-size: 12px;
      color: $gray3;
      background: linear-gradient(180deg, #FFFFFF 77.5%, #F5F2F2 100%);
      border: 1px solid #E2E6ED;
      border-radius: 5px;
      padding: 13px 15px;

      svg {
        margin-right: 12px;
      }
    }
  }
}
