@import '../../../styles/colors';

.addButtonsContainer {
  display: flex;
  justify-content: center;
  .addButtons {
    width: 120px;
    margin-bottom: 0;
    & svg {
      margin-right: 10px;
    }
    .deleteButton {
      color: $red1;
      background: $white;
    }
    .duplicateButton {
      color: $blue1;
      background: $white;
    }
    .saveButton {
      color: $white;
      background: $blue2;
    }
  }
  & > div:first-child {
    margin-right: 22px;
  }
  & > div:last-child {
    margin-left: 22px;
  }
}
