@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 27px 1px 100px 1px;
    min-width: 1100px;

    .itemContainer {
      padding-bottom: 43px;
      border-bottom: 1px solid $lightGray4;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      .headerContainer {
        width: calc(50% - 15px);
        margin-bottom: 30px;

        .titleContainer {
          width: calc(100% - 98px);
          background: none;
          border: none;
          padding: 0;
          margin-bottom: 12px;

          .title {
            font-weight: 600;
            font-size: 14px;
            text-transform: capitalize;
          }
        }

        .headerOptions {
          display: flex;
        }

        .textareaContainer {
          width: calc(100% - 98px);
          margin-top: 18px;

          textarea {
            resize: none;
            max-height: 150px;
            line-height: 17px;
            font-size: 14px;
            margin: 0;
          }
        }
      }

      .potentialPartnershipsContainer {

        .potentialPartnershipsHeader {
          display: flex;
          margin-bottom: 16px;
          color: #363A42;
          font-weight: 600;
          font-size: 14px;

          span {
            flex: 1;
            &:first-child {
              margin-right: 15px;
            }
            &:last-child {
              margin-left: 15px;
            }
          }
        }

        .potentialPartnershipsValues {
          display: flex;
          align-items: flex-start;

          margin-bottom: 16px;

          .stakeholderTypesContainer {
            margin-bottom: 0;
            width: calc(50% - 15px);
            margin-right: 30px;

            input {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }

        .buttonContainer {
          margin-bottom: 0;

          .button {
            font-weight: 600;
            font-size: 12px;
            color: $blue1;
            box-shadow: none;
            background: $lightGray5;
            border: 1px solid $blue1;
            border-radius: 4px;
            padding: 12px;

            svg {
              margin-right: 12px;
            }
          }
        }
      }

      .partnershipItemContainer {
        width: calc(50% - 15px);
        display: flex;
      }

      .inputContainer {
        margin-bottom: 0;

        input {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .selectContainer {
        margin-bottom: 0;
        margin-left: 8px;
        max-width: 90px;

        input {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .selectContainerMeaning {
        max-width: 100%;
        margin-left: 0;
      }

      .selectInputItem {
        text-transform: none;
      }
    }
  }
}
