@import '../../../styles/colors';

.container {
  margin-top: 16px;
  width: 540px;
  .button {
    text-transform: uppercase;
    background: $green;
  }

  .buttonContainer {
    margin-bottom: 0;

    button {
      background-color: $red;
      text-transform: uppercase;
    }
  }
}
