@import '../../../styles/colors';

.container {
  display: flex;
  justify-content: center;
  padding: 50px 20px;
  .content {
    background: $lightBlueOpacity;
    max-width: 720px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.21);
  }
  .wrapper {
    padding: 40px 92px 48px;
    min-width: 540px;
    width: 100%;
  }
}
