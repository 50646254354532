@import '../../../styles/colors';

.container {
  display: flex;
  margin-bottom: 40px;
   .item {
     width: 32px;
     height: 32px;
     display: flex;
     justify-content: center;
     align-items: center;
     background: $lightBlue;
     border: 1px solid $lightGray2;
     border-radius: 4px;
     margin-right: 10px;
     font-weight: 500;
     font-size: 14px;
     cursor: pointer;
     &:last-child {
       margin-right: 0;
     }
   }
  .selected {
    background: $gray;
  }
  .disabled {
    opacity: 0.5;
  }
}

