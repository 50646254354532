@import '../../../styles/colors';

.container {

  margin: 0 -15px;

  .subtitle {
    font-weight: 600;
    font-size: 26px;
    color: $black;
    margin-top: 20px;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 31px;

    .button {
      flex: 1;
      margin-bottom: 0;

      &:first-child {
        margin-right: 28px;
      }

      button {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: $red3;
      }

      .yesButton {
        background: $blue2;
        color: $white;
      }
    }
  }
}
