@import '../../../styles/colors';

.driversContainer {
  display: flex;
  width: 100%;
  margin-bottom: 48px;

  .driversWrapper {
    display: flex;
    width: calc(100% - 191px);

    .driversItemContainer {
      width: 33.33%;
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }

      .driversItemTitleContainer {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .driversItemTitle {
          font-weight: 600;
          font-size: 15px;
          line-height: 17px;
          color: $black;
          display: flex;
          align-items: center;
          margin-right: 7px;
        }
      }

      .driversItem {
        display: flex;
        margin-bottom: 16px;

        .driversItemDriver {
          margin-bottom: 0;
          width: 70%;
          margin-right: 8px;
          input {
            font-size: 14px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .driversItemRate {
          margin-bottom: 0;
          width: 30%;
          min-width: 76px;

          input {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .driversButtonContainer {
        margin-bottom: 0;

        .driversButton {
          color: $blue1;
          box-shadow: none;
          background: $lightGray5;
          border: 1px solid $blue1;
          border-radius: 4px;
          padding: 12px 0;

          svg {
            margin-right: 12px;
          }
        }
      }
    }
  }

  .fullWidth {
    width: 100%;
  }
}

.empty {
  width: 190px;
}
