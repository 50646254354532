@import '../../../styles/colors';

.driversContainer {
  display: flex;
  margin-bottom: 25px;

  .topMarketContainer {
    flex: 0 0 282px;
    border-right: 1px solid $lightGray2;
    padding-right: 16px;
    padding-left: 1px;

    .titleContainer {
      display: flex;
      height: 134px;

      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: $gray3;
        margin-right: 9px;
        margin-top: 13px;
      }

      .tipTextContainer {
        margin-top: 5px;
      }
    }

    .topMarketDriversItemContainer {
      display: flex;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .inputContainer {
        margin: 0 8px 0 0;

        input {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }


      .selectInputContainer {
        flex: 0 0 92px;
        margin-bottom: 0;

        input {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .selectInputMeaning {
        flex: 1;
        margin-right: 8px;
      }
    }
  }

  .competitorsContainer {
    width: 100%;
    padding-left: 15px;
    display: flex;

    .competitorsColumnContainer {
      flex: 1;
      max-width: 25%;
      position: relative;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;

        .line {
          width: 100%;
        }
      }

      .line {
        position: absolute;
        top: 110px;
        height: 1px;
        background-color: $lightGray2;
        width: calc(100% + 16px);
      }

      .verticalLine {
        position: absolute;
        height: 100%;
        background-color: $lightGray2;
        width: 1px;
        right: -8px;
      }

      .competitorsColumnTitle {
        padding-bottom: 32px;
        height: 134px;;
        display: grid;
        .subTitle {
          display: block;
          padding-bottom: 5px
        }

        .subTitleCompetitor {
          visibility: hidden;
        }

        .text {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          height: 19px;
          color: $black;
          padding-right: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 8px;
        }

        .titleInput {
          margin-bottom: 6px;
          input {
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            color: $black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 0 2px 0;
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
          }
        }

        .edit {
          position: absolute;
          top: 24px;
          right: 0;
          cursor: pointer;
          line-height: 12px;
        }
      }

      .titleOrganization {}

      .competitorsColumnItem {
        input {
          font-size: 14px;
          font-weight: 500;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
