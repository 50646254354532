@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .content {
    margin: 30px 0 48px 0;
    min-width: 900px;
    position: relative;

    .tipCustomer {
      display: inline-block;
      padding-left: calc(50% - 47px);
    }

    .coreMeaningContainer {
      display: flex;
      width: 100%;
      margin-bottom: 26px;
    }
  }

  .modalContainer {
    margin-top: 36px;

    .modalButtonContainer {
      margin-bottom: 0;

      .modalButton {
        background: $blue2;
        box-shadow: 0 4px 10px $blackOpacity3;
        border-radius: 8px;
        text-transform: uppercase;
      }
    }
  }

  .customerProfileContainer {
    position: absolute;
    top: 330px;
    width: 160px;
    padding-left: 1px;

    .input {
      margin-bottom: 16px;

      input {
        font-weight: 400;
        font-size: 13px;
        line-height: 14px;
        padding: 14px 15px;
      }

      .label {
        margin-bottom: 6px;
        font-weight: 600;
        font-size: 15px;
      }
    }

    .biographyLabel {
      color: $gray3;
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 5px;
      display: inline-block;
    }

    textarea {
      height: 100px;
      padding: 11px 15px;
      resize: none;
    }
  }
}
