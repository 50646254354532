@import '../../../../styles/colors';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 144px;
    height: 144px;
    border-radius: 50%;
    border: 20px solid $blue5;
    margin: 33px 0 19px 0;
  }

  .title {
    color: $black;
    font-weight: 700;
    font-size: 19px;
    text-transform: uppercase;
    line-height: 19px;
    margin-bottom: 37px;
  }

  .subtitle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .subtitleText {
      color: $black;
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      line-height: 17px;
      margin: 0;
    }

    .buttonContainer {
      margin-bottom: 0;
      .button {
        color: $black;
        font-weight: 600;
        font-size: 12px;
        padding: 12px 24px;
        box-shadow: none;
        background: #F9FBFF;
        border: 1px solid #E2E6ED;
        border-radius: 4px;

        svg {
          margin-right: 12px;
        }
      }
    }
  }

  .filterContainer {
    display: flex;
    width: 100%;
    margin-bottom: 37px;

    .filter {
      margin: 0 10px 0 0;

       input {
         background: $lightBlue;
         border-color: $lightGray2;
         font-weight: 400;
         font-size: 14px;

         &::placeholder {
           color: $gray;

         }
       }

      .accessLevel {
        text-transform: capitalize;
      }
    }

    .selectInput {
      input {
        text-transform: capitalize;
      }
    }

    .buttonFilterContainer {
      margin-bottom: 0;

      .buttonFilter {
        color: $white;
        font-weight: 400;
        font-size: 14px;
        background: $blue5;
        padding: 0 15px;
        height: 100%;
        box-shadow: none;
      }
    }
  }

  .users {
    width: 100%;
    margin-bottom: 60px;

    .row {
      display: flex;
      padding: 9px 50px;
      font-weight: 400;
      font-size: 14px;
      color: $gray;

      button {
        color: $gray;
        &:disabled {
          color: $gray;
          cursor: default;
        }
      }

      .column {
        width:  23%;
        padding: 11px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .columnSmall {
        display: flex;
        align-items: center;
        width: 15.5%;
        padding: 11px 10px;

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-transform: capitalize;
        }
      }
      .columnAction {
        width: 72px;
        display: flex;
        align-items: center;
        padding-left: 5px;

        .buttonActionContainer {
          margin-bottom: 0;

          &:first-child {
            margin-right: 10px;
          }

          .buttonAction {
            padding: 6px;
            background: $white;
            border: 1px solid #D7E2F0;
            border-radius: 3px;
            box-shadow: none;
          }
        }
      }
    }

    .line {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0 50px;
      color: $black;
      .email {
        color: $blue5
      }

      .name {
        color: $blue5;
        cursor: pointer;
      }
    }
  }
}


