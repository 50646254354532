@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 38px 1px;
    min-width: 1000px;

    .title {
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      color: $gray3;
      margin-bottom: 18px;
    }

    textarea {
      resize: none;
      height: 300px;
      margin-bottom: 0;
    }
  }
}
