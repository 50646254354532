@import '../../../styles/colors';

.container {
  .tip {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 2px;

    .tipTextContainer {
      display: none;
      background: $orange;
      border: 1px solid $white;
      box-shadow: 0 4px 11px $blackOpacity2;
      border-radius: 6px;
      color: $white;
      padding: 0;
      position: absolute;
      font-weight: 500;
      font-size: 14px;
      z-index: 2;
      top: 70%;
      left: 50%;
      margin-top: 14px;
      transform: translateX(-50%);
      width: 290px;

      /* width */
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 3px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $gray;
        border-radius: 3px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {}

      button {
        color: $white;
        background: $orange;
        padding: 0;
        display: inline;
        font-weight: 500;
        font-size: 14px;
      }

      .text {
        max-height: 260px;
        overflow-y: auto;
        overflow-x: hidden;

        p {
          padding: 0 10px;
        }
      }
    }
  }

  .tip:hover .tipTextContainer {
    display: block;
  }

  .tipText {
    position: relative;
  }

  .triangle {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 0;
    height: 0;
    z-index: 3;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $white;
  }
}
