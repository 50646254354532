@import '../../../styles/colors';

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $lightBlue;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 30px;
  text-align: center;

  .title {
    font-weight: 700;
  }

  .email {
    font-weight: 500;
    text-decoration: underline;
    color: $lightBlue;
  }
}

.inputContainer {
  margin-top: 14px;

  .passwordInput {
    input {
      padding-right: 45px;
    }
  }

  .forgotPass {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 14px;
    a {
      text-decoration: none;
      color: $lightBlue;
    }
  }

  .createAccount {
    text-align: center;
    font-size: 14px;
    color: $lightBlue;
    font-weight: 300;
    a {
      color: $lightBlue;
      font-weight: 700;
      text-decoration: none;
    }
  }

  .button {
    margin-bottom: 32px;
    button {
      text-transform: uppercase;
      background: $green;
    }
  }
}
