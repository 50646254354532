@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 27px 1px 50px 1px;
    min-width: 1000px;

    .itemContainer {
      padding-bottom: 30px;
      border-bottom: 1px solid $lightGray4;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      .headerContainer {
        display: flex;
        margin-bottom: 16px;
        color: $gray3;

        .label {
          width: 100%;
          .titleContainer {
            background: none;
            border: none;
            padding: 0;
            line-height: 17px;
            .title {
              font-weight: 600;
              color: $gray3;
            }
          }
        }

        .titleWithOutTip {
          padding-bottom: 6px;
        }

        .offering {
          display: flex;
          align-items: flex-end;
          width: 33.33%;
          margin-right: 20px;

          .inputContainer {
            margin: 0 8px 0 0;

            input {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .selectContainer {
            max-width: 70px;
            min-width: 70px;
            margin: 0;
            input {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }

        .currency {
          display: flex;
          align-items: flex-end;
          width: 33.33%;

          .selectContainer {
            width: 230px;
            margin: 0;

            input {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .selectCurrency {
            margin-right: 8px;
          }
        }

        .empty {
          width: 33.33%;
          margin-left: 20px;
        }
      }

      .valuesContainer {

        .valuesHeader {
          display: flex;
          margin-bottom: 16px;

          span {
            font-size: 14px;
            font-weight: 600;
            color: $gray3;
            width: 33.33%;

            &:first-child {
              margin-right: 20px;
            }
            &:last-child {
              margin-left: 20px;
            }
          }
        }

        .valuesRowContainer {
          display: flex;
          margin-bottom: 16px;

          .rowItemContainer {
            width: 33.33%;
            display: flex;
            position: relative;

            span {
              position: absolute;
              right: 48px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 14px;
              font-weight: 500;
            }

            .inputContainer {
              margin-bottom: 0;
              margin-right: 8px;

              input {
                font-size: 14px;
                font-weight: 500;
              }
            }

            .currency {
              margin-right: 0;
              max-width: 70px;
              min-width: 70px;

              input {
                padding-left: 26px;
              }
            }

            &:first-child {
              margin-right: 20px;
            }
            &:last-child {
              margin-left: 20px;
            }
          }
        }

        .buttonContainer {
          margin-bottom: 0;

          button {
            font-weight: 600;
            font-size: 12px;
            color: $blue1;
            box-shadow: none;
            background: $lightGray5;
            border: 1px solid $blue1;
            border-radius: 4px;
            padding: 12px;

            svg {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}
