@import '../../../styles/colors';

.legendContainer {
  background: $lightGray6;
  border-radius: 12px;
  padding: 41px;
  display: flex;
  align-items: center;

  .title {
    color: $black;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin: 0 18px 0 0;
    padding: 0;
  }

  .legendItem {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    position: relative;
    padding-left: 10px;
    color: $blue11;
    margin-right: 26px;
    &:before {
      content: '';
      position: absolute;
      top: 40%;
      left: 0;
      transform: translateY(-50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: $blue11;
    }
  }

  .weakness {
    color: $orange2;
    &:before {
      background: $orange2;
    }
  }

  .opportunity {
    font-weight: 700;
    color: $green2;

    &:before {
      background: $green2;
    }
  }

  .threat {
    color: $red3;
    font-weight: 700;
    &:before {
      background: $red3;
    }
  }
}
