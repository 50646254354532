@import '../../../styles/colors';

.container {
  position: absolute;
  width: 100%;
  background: $white;
  overflow-y: auto;
  max-height: 200px;
  border-color: $lightGray2;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  box-shadow: 0 4px 11px $blackOpacity2;
  border-radius: 6px;
  box-sizing: border-box;
  font-weight: 500;
  color: $lightBlue2;
  z-index: 4;
}
