@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .contentContainer {
    margin: 27px 1px 100px 1px;
    min-width: 1000px;
    display: flex;

    .itemContainer {
      width: 33.33%;

      &:first-child {
        padding-right: 12px;
      }
      &:nth-child(2) {
        padding: 0 6px;
      }
      &:nth-child(3) {
        padding-left: 12px;
      }
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        margin-bottom: 38px;
      }
    }

    .titleContainer {
      background: none;
      border: none;
      padding: 0 96px 16px 0;

      .title {
        font-weight: 600;
        font-size: 14px;
        padding: 6px 0;
      }
    }

    .valuesContainer {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .inputContainer {
        margin-bottom: 0;
        margin-right: 8px;
      }

      .selectContainer {
        margin-bottom: 0;
        max-width: 90px;
      }
    }

    input {
      font-weight: 500;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
