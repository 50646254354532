@import '../../../styles/colors';

.container {
  height: 100%;
  overflow: auto;

  .content {
    margin: 30px 0 48px 0;
    min-width: 900px;
    position: relative;

    .tipStakeholder {
      display: inline-block;
      padding-left: calc(50% - 47px);
    }

    .coreMeaningContainer {
      display: flex;
      width: 100%;
      margin-bottom: 26px;
    }

    .rankContainer {
      position: absolute;
      top: 310px;
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;
        color: $black;
        text-transform: capitalize;
      }

      .selectContainer {
        margin: 0 0 0 10px;
        width: 90px;

        input {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .profilesContainer {
      position: absolute;
      top: 384px;
      width: 160px;
      border-top: 1px solid $lightGray2;
      padding-top: 30px;

      .profilesContent {
        border: 1px solid $lightGray2;
        background: #F9FBFF;
        padding: 20px 23px;
        border-radius: 4px;
        max-height: 400px;
        overflow: auto;

        .title {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #363A42;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 13px;
        }

        .profilesItem {
          font-weight: 500;
          font-size: 14px;
          color: $blue10;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          display: block;

          &:not(:last-child) {
            margin-bottom: 13px;
          }
        }
      }
    }
  }
}
