.triggerContainer {
  top: auto !important;
  bottom: 110% !important;

  .triangle {
    transform: translateX(-50%) rotateX(180deg);
    top: 100%;
  }
}

.functionalContainer {
  left: -20% !important;

  .triangle {
    transform: translateX(40%) rotateX(180deg);
  }
}

.driverContainer {
  left: -320% !important;

  .triangle {
    left: 76%
  }
}
