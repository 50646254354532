@import '../../../../styles/colors';

.header {
  background: linear-gradient(88.38deg, #126FBC 34.4%, #178ACC 54.47%);

  .container {

    .logoContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        padding: 15px 0;
        display: block;
      }

      .buttonContainer {
        margin-bottom: 0;

        .button {
          color: $blue3;
          padding: 8px 15px;
          border-radius: 4px;
          text-transform: capitalize;
          border: none;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .tabsContainer {
        display: flex;
        a {
          font-weight: 700;
          font-size: 12px;
          color: $blue5;
          padding: 12px 40px 10px 40px;
          background: linear-gradient(180deg, #FFFFFF 77.5%, #F5F2F2 100%);
          margin-right: 11px;
          border: 1px solid #E2E6ED;
          border-radius: 8px 8px 0 0;
          text-transform: uppercase;
          text-decoration: none;
        }

        .activeTab {
          background: linear-gradient(180deg, #FFFFFF 77.5%, #FFFFFF 100%);
          border-bottom: none;
          color: $black
        }
      }

      .menuContainer {
        display: flex;
        align-items: flex-end;

        .menuContainerItem {
          position: relative;
          margin: 0 0 8px 8px;
          display: flex;
          align-items: flex-end;
          cursor: default;
          padding: 0;
          background-color: transparent;

          .imageHover {
            position: absolute;
            visibility: hidden;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}
