@import '../../../styles/colors';

.icon {
  padding: 26px 0;
  display: block;
  margin: 0 auto;
}

.title {
  text-align: center;
  margin-bottom: 15px;
}

.text {
  font-weight: 500;
  font-size: 14px;
  color: $lightBlue;
  text-align: center;
  margin-bottom: 7px;
}

.resend {
  text-align: center;
  font-size: 14px;
  color: $lightBlue;
  font-weight: 300;
  span {
    color: $white;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.button {
  margin: 34px 0 0 0;

  button {
    text-transform: uppercase;
    background-color: $green;
  }
}
