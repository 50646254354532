@import '../../../styles/colors';

.label {
  font-weight: 600;
  font-size: 14px;
  color: $gray3;
  line-height: 15px;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 16px;
}

.container {
  width: 100%;
  margin-bottom: 16px;

  .wrapper {
    width: 100%;
    position: relative;

    input:disabled {
      cursor: pointer;
      &::placeholder {}
    }

    input {
      border-radius: 4px;
      width: 100%;
      padding: 12px 24px 12px 16px;
      border: 1px solid $lightGray2;
      background: $lightBlue;
      font-size: 13px;
      box-sizing: border-box;
      color: $black;
      &::placeholder {
        color: $gray;
      }
      &:focus {
        outline: 1px solid $blue1;
      }
    }

    .icon {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      cursor: pointer;

      svg {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        cursor: pointer;
      }

    }

    .errorIcon {
      height: calc(100% - 23px);
    }

    .error {
      padding: 10px 0 0 16px;
      font-size: 13px;
      color: $red
    }

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 3px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $gray;
      border-radius: 3px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {}
  }
}



