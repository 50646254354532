@import '../../../../styles/colors';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    max-width: 540px;
    width: 100%;
    margin: 32px 0;

    .title {
      color: $black;
      font-weight: 700;
      font-size: 19px;
      text-transform: uppercase;
      line-height: 19px;
      margin-bottom: 24px;
    }

    .inputContainer {
      margin-bottom: 18px;

      input {
        text-transform: capitalize;
      }

      .inputContainerItem {
        text-transform: capitalize;
      }
    }

    .notCapitalize {
      input {
        text-transform: none;;
      }
    }

    .errorText {
      display: inline-block;
      font-size: 13px;
      font-weight: 400;
      color: $red;
      padding: 0 0 0 16px;
    }

    .text {
      color: $black;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 16px;
      padding-top: 6px;
    }

    .radioContainer {
      display: flex;
      margin-bottom: 20px;
      div:first-child {
        margin-right: 32px;
      }
    }

    .rankContainer {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 600;

      .rateInputContainer {
        margin: 0 0 0 20px;
        width: 90px;

        input {
          font-weight: 600;
        }
      }
    }

    .buttonsContainer {
      display: flex;
      background: $lightGray6;
      border-radius: 12px;
      padding: 24px;

      .buttonContainer {
        margin-bottom: 0;
        width: 100%;

        &:first-child {
          margin-right: 9px;
          .button {
            color: $blue6;
          };
        }

        &:last-child {
          margin-left: 9px;
          .button {
            background: $blue7;
            color: $white;
          };
        }

        .button {
          font-weight: 700;
          font-size: 14px;
          padding: 22px 5px;
          border: none;
        }
      }
    }
  }
}
