@import '../../../styles/colors';

.competitorModalContainer {
  margin-top: 16px;

  .competitorModalButtonContainer {
    margin-bottom: 0;
    .competitorModalButton {
      background: $blue2;
      text-transform: uppercase;
    }
  }

  .competitorModalTitle {
    color: $black;
    font-weight: 600;
    font-size: 24px;
    line-height: 42px;
    margin-bottom: 17px;
  }

  .competitorModalEditButtonsContainer {
    display: flex;
    justify-content: space-around;

    .editButtonContainer {
      width: 120px;
      margin-bottom: 0;

      & svg {
        margin-right: 10px;
      }

      .deleteButton {
        color: $red;
        display: flex;
        align-items: center;
      }

      .editButton {
        color: $white;
        background: $blue2;
      }
    }
  }
}
