@import '../../../styles/colors';

.linkContainer {
  width: 190px;
  position: relative;
  margin-top: 20px;
  display: flex;
  z-index: 2;

  .linkWrapper {
    width: 160px;
    position: relative;
    &:hover {
      .linkAction {
        display: flex;
        justify-content: space-around;
      }
    }

    .linkContent {
      width: 100%;
      background: $lightBlue;
      border: 1px solid $lightGray2;
      padding: 11px 13px;
      border-radius: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      svg {
        margin-right: 9px;
      }

      span {
        font-weight: 400;
        font-size: 13px;
        color: $gray;
      }
    }

    .linkAction {
      position: absolute;
      top: 0;
      width: 100%;
      border-radius: 4px;
      margin-top: 10px;
      padding: 40px 5px 5px 5px;
      display: none;

      .linkButtonContainer {
        margin-bottom: 0;

        .linkButton {
          padding: 10px;

          &:hover, &:disabled {
            opacity: 1;
          }
        }

        .linkAddButton {
          padding: 10px 30px;
          color: $blue1;
          text-transform: uppercase;

          &:hover, &:disabled {
            opacity: 1;
          }

          svg {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .tipContainer {
    margin: 7px auto 0;
    p {
      padding: 0 10px;
    }
  }
}
