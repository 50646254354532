@import '../../../styles/colors';

.container {
  margin-top: 32px;

  .passwordInput {
    input {
      padding-right: 45px;
    }
  }

  .dropdownContent {
    position: fixed !important;
    bottom: 20px;
    width: 540px !important;
    background: $lightBlue;
    overflow-y: auto;
    min-height: 90vh;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
  }
}
